/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  AccessOptions,
  AssignmentCardType,
  RolesEnum,
  fetchDataFromS3Bucket,
  formatDate,
  getAccessFacilityLockKeyLabel,
  getAuthData,
  getStoredCustomerDetails,
  getTimeFormat,
  hours,
  removeNonValueFromStringArray,
} from '../../../../Shared/Utilities/utils';
import {
  JobType,
  BooleanType,
  PreShiftChecklistTemplate,
  PreShiftChecklistTemplateInput,
  PreShiftChecklistTemplateItem,
  PreShiftChecklistTemplateItemActionType,
  PreShiftChecklistTemplateItemInput,
  PreShiftChecklistTemplateItemQuantityType,
  UserForUserManagement,
  WorkOrderStatus,
  WorkOrderYesNoType,
  UpdateShiftDetailsInput,
  UpdateWorkOrderInput,
  WorkOrderTeam,
} from '../../../../API';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import WorkOrdersApiService from '../../Services/WorkOrdersService';

import {
  IUser,
  MasterData,
  PreShiftChecklistTemplateItemActionTypeNew,
  UserType,
} from '../../../../Shared/Constants/App';
import { IAccessFacilityData } from './ShowFacilityDetails';
import { IVendorProofOfCompletion } from './TasksRequireProofs';
import { IDataProps } from './PreShiftCheckList';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import JobAssignmentCard, { ITeamMemberData, IWorkOrderTeam } from './JobAssignmentCard';

import AddItem from './AddItem';
import ModifyPopUp from './ModifyPopUp';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import theme from '../../../../Shared/Themes/theme';

import { Icons } from '../../../../Shared/Constants/Icons';

import {
  AssignJobText,
  AwardJobText,
  CardContainer,
  DetailsContainer,
  ShiftDetailsContainer,
  Match,
  MatchText,
  TimeAndInstructions,
  TitleCard,
  buttonStyles,
  AddDataContainer,
} from './OneTimeJobDetails.Style';
import WarningMessage from '../../../../Shared/Components/Common/WarningMessage/WarningMessage';
import moment from 'moment';
import ShiftTimeSelection from './ShiftTimeSelection';
import { LoaderTag } from '../ActionItems/ActionItems.Style';

const behalfOfAssociate = false;
const isJobPunchedOut = false;

interface ShiftDetails {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId?: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
}

export type BidSummary = {
  bidVersion: number | null;
  isCustomerApprovalAvailable: boolean;
};

export type ServiceCategoryV3 = {
  taskId: string;
  serviceCategoryId: string;
  serviceCategoryName: string;
  categoryImageUrl?: string | null;
  categoryDescription?: string | null;
  orgId: string;
  mdServiceType: string;
  taskName: string;
  taskImageUrl?: string | null;
  taskDescription?: string | null;
  unit?: string | null;
  mdAreaType?: string | null;
  modifiedBy?: string | null;
  createdBy?: string | null;
};

export type ServicesWithTasks = {
  __typename: 'ServicesWithTasks';
  mdServiceId?: string | null;
  mdServiceName?: string | null;
  tasks?: Array<TasksForServices | null> | null;
};
export type TasksForServices = {
  __typename: 'TasksForServices';
  mdTaskId?: string | null;
  mdTaskName?: string | null;
  woScheduleServiceId?: string | null;
  isProofOfCompletionRequired?: WorkOrderYesNoType;
};
export interface IShiftTimings {
  endTime: { hours: number | string; minutes: number | string };
  shiftDetailId?: string;
  startTime: { hours: number | string; minutes: number | string };
  shiftStartTime?: string;
  shiftEndTime?: string;
  shiftEndDate?: string;
  shiftStartDate?: string;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  offsetDays?: number;
}

export interface IShiftDetails {
  endTime: { hours: number | string; minutes: number | string };
  jobEndDate?: string;
  jobStartDate?: string;
  startTime: { hours: number | string; minutes: number | string };
  scheduleStartDate: string;
  scheduleEndDate: string;
  offsetDays?: number;
}

interface IFinalTeamData {
  shiftDetailId: string;
  teamData: ITeamMemberData[];
  checkedTeam: ITeamMemberData[];
}
interface IProofOfCompletion {
  shiftDetailId: string;
  transformedServiceAndTaskData: any;
}

export type WorkOrderTeamWithAssignment = {
  __typename: 'WorkOrderTeamWithAssignment';
  teamId: string;
  vendorId?: string | null;
  userId?: string | null;
  roleId?: string | null;
  isSupervisor?: boolean | null;
  workOrderId?: string | null;
  woScheduleMasterId?: string | null;
  associateStartDate?: string;
  associateEndDate?: string;
  cognitoUserId?: string | null;
  workDayId?: string | null;
  noShow?: boolean | null;
  cantMakeIt?: boolean | null;
  jobAccepted?: boolean | null;
};

export interface AllScheduleDetailsQuery {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
  scheduleStartDate: string;
  scheduleEndDate: string;
  scheduleName?: string | null;
  scheduleSubName?: string;
  endDateOffset?: number | null;
  workOrder?: {
    __typename: 'ShiftDetailsWorkOrder';
    jobStartDate?: string | null;
    jobEndDate?: string | null;
    actualStartDate?: string | null;
    actualEndDate?: string | null;
  } | null;
  scheduleAssignments: WorkOrderTeamWithAssignment[];
  scheduleServices?: Array<{
    __typename: 'ServicesWithTasks';
    mdServiceId?: string | null;
    mdServiceName?: string | null;
    tasks?: Array<{
      __typename: 'TasksForServices';
      mdTaskId?: string | null;
      mdTaskName?: string | null;
      woScheduleServiceId?: string | null;
      isProofOfCompletionRequired?: WorkOrderYesNoType | null;
    } | null> | null;
  } | null> | null;
  preShiftCheckListTemplates?: Array<{
    __typename: 'PreShiftChecklistTemplateForSchedule';
    preShiftChecklistTemplateId: string;
    woScheduleMasterId: string;
    checkListName: string;
    checkListIconUrl: string;
    mdPreShiftCheckListCategoryId: string;
    buildingAccessConfirmation: WorkOrderYesNoType;
    securityAccessConfirmation: WorkOrderYesNoType;
    safetyConfirmation: WorkOrderYesNoType;
    msdsChemicalSafety: WorkOrderYesNoType;
    workStatus: WorkOrderStatus;
    workOrderId: string;
    workOrderRecSeq: number;
    preShiftChecklistTemplateItems?: Array<{
      __typename: 'PreShiftChecklistTemplateItem';
      itemName: string;
      preShiftChecklistTemplateItemId: string;
      mdItemActionType: PreShiftChecklistTemplateItemActionType;
      quantity: number;
      mdQuantityType: PreShiftChecklistTemplateItemQuantityType;
    } | null> | null;
  } | null> | null;
}

export interface ICanVendorStartJob {
  workOrderId: string;
  shiftTimings: AllScheduleDetailsQuery[];
  workOrderStatus: string;
  startDate: string;
  jobCustodian?: string;
  isOnlySubVendor: boolean;
  isSelfPerformJob: boolean;
}

export interface IDmConfiguredSupervisors {
  schedules: ShiftDetails[];
  workOrderId: string;
}

export interface IStartJob {
  workOrderScheduleTeam: any[];
  shiftDetails: { endTime: string; startTime: string };
  workOrderStatus: string;
  startDate: string;
  jobCustodian?: string;
  isOnlySubVendor: boolean;
  isSelfPerformJob: boolean;
}

const AwardJob = ({ jobData }: any): JSX.Element => {
  const [startJobOpen, setStartJobOpen] = useState(false);
  const [startJobLoading, setStartJobLoading] = useState(false);

  const { t } = useTranslation(['assignJob', 'vendor']);
  const { divider } = buttonStyles;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  const [assignmentType, setAssignmentType] = useState<AssignmentCardType>();
  const { role, isDistrictManager } = getAuthData();
  const [isOnlyPrimeVendor, setIsOnlyPrimeVendor] = useState<boolean>(false);
  const [isOnlySubVendor, setIsOnlySubVendor] = useState<boolean>(false);
  const [isSelfPerformJob, setIsSelfPerformJob] = useState<boolean>(false);
  const [workOrderId, setWorkOrderId] = useState<string>('');
  const [shiftDetailId, setShiftDetailId] = useState<string>('');
  const [subWorkOrderId, setSubWorkOrderId] = useState<string>('');
  const [parentWorkOrderId, setParentWorkOrderId] = useState<string>('');
  const [shiftTimings, setShiftTimings] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workOrderData, setWorkOrderData] = useState<any>({});
  const [workOrderStatus, setWorkOrderStatus] = useState<any>();
  const [hasChild, setHasChild] = useState<boolean>(false);
  const [originalTeamData, setOriginalTeamData] = useState<ITeamMemberData[]>([]);
  const [teamData, setTeamData] = useState<ITeamMemberData[]>([]);
  const [checkedSupervisors, setCheckedSupervisors] = useState<any>([]);
  const [isTeamDataLoading, setIsTeamDataLoading] = useState<boolean>(false);
  const [checkedAssociates, setCheckedAssociates] = useState<any>([]);
  const [originalAssociateTeamData, setOriginalAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [associateTeamData, setAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [facilityAccessData, setFacilityAccessData] = useState<IAccessFacilityData[] | []>([]);
  const [checkListData, setCheckListData] = useState<IDataProps[]>([]);
  const [isAssignSupervisorLoading, setIsAssignSupervisorLoading] = useState<boolean>(false);
  const [isAssignAssociateLoading, setIsAssignAssociateLoading] = useState<boolean>(false);
  const [isFacilityAccessLoading, setIsFacilityAccessLoading] = useState<boolean>(false);
  const [isTaskRequireProofLoading, setIsTasksRequireProofLoading] = useState<boolean>(false);
  const [isPreShiftChecklistLoading, setIsPreShiftChecklistLoading] = useState<boolean>(false);
  const [addItemCardOpen, setAddItemCardOpen] = useState<boolean>(false);
  const [addItemData, setAddItemData] = useState<any>({});
  const [selectedCatalogue, setSelectedCatalogue] = useState('');
  const isAssociate = (role === UserType.Associate || behalfOfAssociate) && !isJobPunchedOut;
  const isVendor = role === UserType.Vendor && !behalfOfAssociate && !isJobPunchedOut;
  const [isAssociatePunchedIn, setIsAssociatePunchedIn] = useState(false);
  const [openShiftTimingsCheck, setOpenShiftTimingsCheck] = useState<boolean>(false);
  const isOtjJob = !!jobData?.jobDetails?.jobCustodian && jobData?.jobDetails?.jobType !== JobType.Recurring;
  const [disableStartJobResult, setDisableStartJobResult] = useState<boolean>(false);
  const [openStartJobWarning, setOpenStartJobWarning] = useState<boolean>(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState('');
  const [tasksWithProof, setTasksWithProof] = useState<IVendorProofOfCompletion[]>([]);
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState<boolean>(false);
  const [isVendorCanStartJob, setIsVendorCanStartJob] = useState<boolean>(false);
  const [isShiftTimingsLoading, setIsShiftTimingsLoading] = useState<boolean>(false);
  const [parentSchedules, setParentSchedules] = useState<any[]>([]);
  const [isPrimeVendorAssignedSupervisors, setIsPrimeVendorAssignedSupervisors] = useState<boolean>(false);

  const providedText = t('vendor:provided');
  const updatedPreShiftChecklistText = t('assignJob:successfullyUpdatedPreShiftChecklist');
  const addText = t('altTexts:add');

  const navigate = useNavigate();

  const isSupervisorsConfiguredForWorkOrder = async (details: IDmConfiguredSupervisors) => {
    const { schedules = [], workOrderId = '' } = details;
    try {
      let isDMConfiguredSupervisors = true;
      await Promise.all(
        schedules?.map(async (schedule) => {
          const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
            workOrderId,
            woScheduleMasterId: schedule?.shiftDetailId ?? '',
          });
          const filteredSupervisors = workOrderTeamResponse?.data?.filter((item) => item.isSupervisor) ?? [];
          if (isEmpty(filteredSupervisors)) {
            isDMConfiguredSupervisors = false;
          }
        })
      );
      return isDMConfiguredSupervisors;
    } catch (e: any) {
      return false;
    }
  };

  const handleStartJobPopup = async () => {
    const isPrimeVendorAssignedSupervisors = !workOrderData?.isInspectionRequiredForParentJob
      ? true
      : await isSupervisorsConfiguredForWorkOrder({
          schedules: shiftTimings ?? [],
          workOrderId: workOrderData?.parentWorkOrderId, // Main work order id for DM team configuration.
        });

    if (!isPrimeVendorAssignedSupervisors) {
      setIsPrimeVendorAssignedSupervisors(true);
    } else if (disableStartJobResult) {
      setOpenStartJobWarning(true);
    } else {
      setStartJobOpen(!startJobOpen);
      setOpenStartJobWarning(false);
      setIsPrimeVendorAssignedSupervisors(false);
    }
  };

  const handleOnClick = async (type: AssignmentCardType, shift: any) => {
    setAssignmentType(type);
    setIsCardOpen(true);
    setShiftDetailId(shift?.shiftDetailId);

    if (type === AssignmentCardType.ProofOfCompletionTasks) {
      const proofData = shift?.tasksProofOfCompletion;
      const newProofOfCompletionData = proofData?.transformedServiceAndTaskData;
      if (newProofOfCompletionData?.length > 0) {
        setTasksWithProof(newProofOfCompletionData);
      } else {
        setTasksWithProof([]);
      }
    }
  };

  const handleOnClose = async (type: AssignmentCardType) => {
    setIsCardOpen(false);
    if (type === AssignmentCardType.ShiftTimings) {
      setOpenShiftTimingsCheck(false);
    }
  };

  const getWorOrderDetailsByWorkOrderId = async (workOrderId: string) => {
    const payload = { workOrderId: workOrderId };
    const workOrderResponseByWorkOrderId = await WorkOrdersApiService.getAllWorkOrders(payload);
    if (workOrderResponseByWorkOrderId.data && workOrderResponseByWorkOrderId.errors.length === 0) {
      setWorkOrderData(workOrderResponseByWorkOrderId.data[0]);
      setWorkOrderStatus(workOrderResponseByWorkOrderId?.data[0]?.status);
      return true;
    } else {
      return false;
    }
  };

  const getPunchInDetails = async (workOrderId: string) => {
    const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(workOrderId);
    const associateDetails = workOrderTeamResponse.data.find((member: any) => !member.isSupervisor);
    const workDayId = associateDetails?.workDayTeam?.workDayId;
    if (workDayId) {
      const latestPunchInOutResponse = await WorkOrdersApiService.getLatestPunchRecords(workDayId);
      if (!isEmpty(latestPunchInOutResponse?.data) && isEmpty(latestPunchInOutResponse?.data?.errorCode)) {
        setIsAssociatePunchedIn(!!latestPunchInOutResponse?.data?.punchType);
      }
    }
  };

  const getWorkOrderDetails = useCallback(async () => {
    setIsShiftTimingsLoading(true);
    const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobData.jobDetails.jobId });
    if (workOrderResponse?.data && workOrderResponse?.errors?.length === 0) {
      const workOrderData = workOrderResponse?.data[0];
      setWorkOrderId(workOrderData?.workOrderId);
      if (workOrderData?.subWorkOrders?.length) {
        setSubWorkOrderId(workOrderData?.subWorkOrders[0]?.workOrderId || '');
      }
      await getPunchInDetails(workOrderData?.workOrderId);
      await getWorOrderDetailsByWorkOrderId(workOrderData?.workOrderId);

      if (workOrderData?.subWorkOrders[0]?.workOrderId) {
        const shiftDetailsResponse = await WorkOrdersApiService.getallShiftDetails(workOrderData?.workOrderId);

        if (shiftDetailsResponse?.data) {
          setParentSchedules(shiftDetailsResponse?.data);
        }
      }

      const isMainJob = workOrderData?.hasChild;
      setHasChild(workOrderData?.hasChild);
      if (!isEmpty(workOrderData?.jobCustodian)) {
        if (isMainJob && !workOrderData?.parentWorkOrderId) {
          setIsOnlyPrimeVendor(true);
        } else if (!isMainJob && workOrderData?.parentWorkOrderId) {
          setParentWorkOrderId(workOrderData?.parentWorkOrderId);
          setIsOnlySubVendor(true);
        } else {
          setIsSelfPerformJob(true);
        }
      }

      await fetchScheduleDetails(
        workOrderData?.subWorkOrders[0]?.workOrderId
          ? workOrderData?.subWorkOrders[0]?.workOrderId
          : workOrderData?.workOrderId
      );

      setIsShiftTimingsLoading(false);
      fetchWorkOrderFacilityAccessInfo(workOrderData?.workOrderId);
    }
  }, [workOrderId]);

  const fetchScheduleDetails = async (workOrderId: string) => {
    setIsShiftTimingsLoading(true);
    try {
      const scheduleDetailsResponse = await WorkOrdersApiService.getallScheduleDetails(workOrderId);

      if (scheduleDetailsResponse.errors.length) {
        setSnackbarMessage(scheduleDetailsResponse.errors[0].message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      const scheduleDetails = scheduleDetailsResponse?.data;

      const allScheduleTeamData: (IFinalTeamData | undefined)[] = await Promise.all(
        scheduleDetails.map(async (schedule: any) => {
          const getVendorTeamResponse = await fetchVendorTeamResponse();
          const getAssociateTeamResponse = await fetchAssociateTeamResponse();
          const scheduleSupervisors = await fetchVendorTeam(getVendorTeamResponse, schedule?.scheduleAssignments);
          const scheduleAssociates = await fetchAssociateTeam(getAssociateTeamResponse, schedule?.scheduleAssignments);
          const tasksProofOfCompletion = await fetchAllWorkOrderData(schedule?.scheduleServices);
          const preShiftCheckList = await fetchAllPreShiftChecklistTemplatesForVendor(
            schedule?.preShiftCheckListTemplates
          );

          return {
            ...schedule,
            scheduleSupervisors: scheduleSupervisors,
            scheduleAssociates: scheduleAssociates,
            tasksProofOfCompletion: tasksProofOfCompletion,
            preShiftCheckList: preShiftCheckList,
          } as IFinalTeamData;
        })
      );
      setShiftTimings(allScheduleTeamData);
      setIsShiftTimingsLoading(false);
    } catch (e) {
      setIsShiftTimingsLoading(false);
      console.error('Errors', e);
    }
  };

  const fetchVendorTeam = async (vendorTeamsUsersExcludingAssociates: any, workOrderTeamResponse: any) => {
    setIsAssignSupervisorLoading(true);
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const tempCheckedMember: ITeamMemberData[] = [];
        const modifiedData = await Promise.all(
          vendorTeamsUsersExcludingAssociates?.map(async (member: UserForUserManagement) => {
            const memberProfileUrl = await fetchDataFromS3Bucket(
              navigate,
              `associate/${member?.userId}/profile-pictures/${member?.userId}`
            );
            const { roleName = '', roleId = '' } =
              member && !isEmpty(member?.roles) && member?.roles?.length ? member?.roles[0] || {} : {};
            const tempMember = {
              id: member.userId,
              userId: member.userId as string,
              name: member.username,
              isChecked: false,
              isAlreadyChecked: false,
              profileImg: memberProfileUrl,
              role: roleName,
              roleId,
              isSupervisor: true,
              teamId: '',
            };
            const workOrderTeamMember = workOrderTeamResponse?.find(
              (teamMember: IWorkOrderTeam) => teamMember.userId === member.userId
            );
            if (workOrderTeamMember && Object.keys(workOrderTeamMember).length !== 0) {
              if (workOrderTeamMember.isSupervisor) {
                tempCheckedMember.push({
                  ...tempMember,
                  isChecked: workOrderTeamMember.isSupervisor as boolean,
                  isAlreadyChecked: workOrderTeamMember.isSupervisor as boolean,
                  teamId: workOrderTeamMember.teamId || '',
                });
                return {
                  ...tempMember,
                  isChecked: workOrderTeamMember.isSupervisor,
                  isAlreadyChecked: workOrderTeamMember.isSupervisor,
                  teamId: workOrderTeamMember.teamId || '',
                };
              }
              return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
            }
            return tempMember;
          })
        );
        setIsAssignSupervisorLoading(false);

        return {
          teamData: modifiedData,
          checkedTeam: tempCheckedMember,
        } as IFinalTeamData;
      } catch (e) {
        console.error('Error', e);
        setIsTeamDataLoading(false);
        setIsAssignSupervisorLoading(false);
      }
    }
  };

  const fetchVendorTeamResponse = async (
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    setIsAssignSupervisorLoading(true);

    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let vendorTeamResponse;
        vendorTeamResponse = isDistrictManager
          ? await WorkOrdersApiService.getVendorTeam(
              userDetails?.data?.userId,
              '',
              BooleanType.False,
              searchString || '',
              pageNumber,
              limit,
              true
            )
          : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.False, true);

        if (vendorTeamResponse?.metadata?.totalCount > 10) {
          vendorTeamResponse = isDistrictManager
            ? await WorkOrdersApiService.getVendorTeam(
                userDetails?.data?.userId,
                '',
                BooleanType.False,
                searchString || '',
                pageNumber,
                vendorTeamResponse?.metadata?.totalCount,
                true
              )
            : await CustomerApiService.getAllUsers(
                vendorId,
                '',
                pageNumber,
                vendorTeamResponse?.metadata?.totalCount,
                BooleanType.False,
                true
              );
        }

        if (vendorTeamResponse.errors.length) {
          setSnackbarMessage(vendorTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsTeamDataLoading(false);
          setIsAssignSupervisorLoading(false);
          return;
        }
        const vendorTeam = vendorTeamResponse?.data ?? [];
        const vendorTeamsUsersExcludingAssociates: UserForUserManagement[] = isDistrictManager
          ? vendorTeam
          : vendorTeam.filter((user: any) => user?.roles[0]?.roleName !== RolesEnum.AssociateTechnician);
        setIsTeamDataLoading(false);
        setIsAssignSupervisorLoading(false);
        return vendorTeamsUsersExcludingAssociates;
      } catch (e) {
        console.error('Error', e);
        setIsTeamDataLoading(false);
        setIsAssignSupervisorLoading(false);
      }
    }
  };

  const fetchAssociateTeam = async (vendorTeamResponse: any, workOrderTeamResponse: any) => {
    setIsAssignAssociateLoading(true);
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const tempCheckedMember: ITeamMemberData[] = [];
        const modifiedVendorTeam = await Promise.all(
          vendorTeamResponse?.map(async (user: UserForUserManagement) => {
            const { roleName = '', roleId = '' } = !isEmpty(user?.roles) ? user?.roles?.[0] || {} : {};
            const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(
              user?.cognitoUserId as string
            );
            const associateId = associateDetails?.data?.associateId ?? '';
            const memberProfileUrl = await fetchDataFromS3Bucket(
              navigate,
              `associate/${associateId}/profile-pictures/${associateId}`
            );

            return {
              id: user?.userId,
              userId: user?.userId,
              name: user?.username,
              isChecked: false,
              isAlreadyChecked: false,
              profileImg: memberProfileUrl,
              role: roleName,
              roleId,
              isSupervisor: false,
              isDisabled: false,
              isJobNotAccepted: false,
            };
          })
        );
        let filteredByRoleData = modifiedVendorTeam;
        if (!onBehalfOfAssociate) {
          filteredByRoleData = modifiedVendorTeam?.filter((item) => item?.role === RolesEnum.AssociateTechnician);
        }
        const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);
        const modifiedData = await Promise.all(
          filteredByAvailableAssociate.map(async (member: any) => {
            const tempMember = { ...member };
            const workOrderTeamMember = workOrderTeamResponse?.find(
              (teamMember: IWorkOrderTeam) => teamMember?.userId === member?.userId
            );
            if (workOrderTeamMember && Object.keys(workOrderTeamMember).length !== 0) {
              // If onBehalfOfAssociate is true, we need already selected supervisor also in disabled state (Associates on the job flow)
              if (onBehalfOfAssociate) {
                tempCheckedMember.push({
                  ...tempMember,
                  isChecked: true,
                  isAlreadyChecked: true,
                  isDisabled: onBehalfOfAssociate,
                  teamId: workOrderTeamMember.teamId || '',
                  isSupervisor: workOrderTeamMember.isSupervisor,
                  associateStartDate: workOrderTeamMember.associateStartDate,
                  associateEndDate: workOrderTeamMember.associateEndDate,
                });
                return {
                  ...tempMember,
                  isChecked: true,
                  isAlreadyChecked: true,
                  isDisabled: onBehalfOfAssociate,
                  teamId: workOrderTeamMember.teamId || '',
                  isSupervisor: workOrderTeamMember.isSupervisor,
                  associateStartDate: workOrderTeamMember.associateStartDate,
                  associateEndDate: workOrderTeamMember.associateEndDate,
                };
              }
              // If onBehalfOfAssociate is false, then we will filter out supervisor (Assign team flow)
              if (!workOrderTeamMember.isSupervisor) {
                tempCheckedMember.push({
                  ...tempMember,
                  isChecked: true,
                  isAlreadyChecked: true,
                  isDisabled: onBehalfOfAssociate,
                  teamId: workOrderTeamMember.teamId || '',
                  associateStartDate: workOrderTeamMember.associateStartDate,
                  associateEndDate: workOrderTeamMember.associateEndDate,
                });
                return {
                  ...tempMember,
                  isChecked: true,
                  isAlreadyChecked: true,
                  isDisabled: onBehalfOfAssociate,
                  teamId: workOrderTeamMember.teamId || '',
                  associateStartDate: workOrderTeamMember.associateStartDate,
                  associateEndDate: workOrderTeamMember.associateEndDate,
                };
              }
              return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
            }
            return tempMember;
          })
        );
        setIsAssignAssociateLoading(false);
        return {
          teamData: modifiedData,
          checkedTeam: tempCheckedMember,
        } as IFinalTeamData;
      } catch (e) {
        console.error('Error', e);
        setAssociateTeamData([]);
        setOriginalAssociateTeamData([]);
        setIsTeamDataLoading(false);
        setIsAssignAssociateLoading(false);
      }
    }
  };

  const fetchAssociateTeamResponse = async (
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    setIsAssignAssociateLoading(true);
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsTeamDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
        let vendorTeamResponse = isDistrictManager
          ? await WorkOrdersApiService.getVendorTeam(
              userDetails?.data?.userId,
              '',
              BooleanType.True,
              searchString || '',
              pageNumber,
              limit,
              true
            )
          : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.True, false);

        if (vendorTeamResponse?.metadata?.totalCount > 10) {
          vendorTeamResponse = isDistrictManager
            ? await WorkOrdersApiService.getVendorTeam(
                userDetails?.data?.userId,
                '',
                BooleanType.True,
                searchString || '',
                pageNumber,
                vendorTeamResponse?.metadata?.totalCount,
                true
              )
            : await CustomerApiService.getAllUsers(
                vendorId,
                '',
                pageNumber,
                vendorTeamResponse?.metadata?.totalCount,
                BooleanType.True,
                false
              );
        }
        if (vendorTeamResponse.errors.length) {
          setSnackbarMessage(vendorTeamResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsTeamDataLoading(false);
        }
        setIsAssignAssociateLoading(false);
        return vendorTeamResponse?.data;
      } catch (e) {
        console.error('Error', e);
        setAssociateTeamData([]);
        setOriginalAssociateTeamData([]);
        setIsTeamDataLoading(false);
        setIsAssignAssociateLoading(false);
      }
    }
  };

  const fetchAllWorkOrderData = async (getScheduleMasterServicesResponse: any) => {
    setIsTasksRequireProofLoading(true);
    try {
      if (!isEmpty(getScheduleMasterServicesResponse ?? [])) {
        const transformedServiceAndTaskData: IVendorProofOfCompletion[] = getTransformedServiceAndTasksData(
          getScheduleMasterServicesResponse ?? []
        );
        return {
          transformedServiceAndTaskData: transformedServiceAndTaskData,
        } as IProofOfCompletion;
      }
    } catch (e) {
      setIsTasksRequireProofLoading(false);
      console.error('all upcoming jobs fetch error', e);
    } finally {
      setIsTasksRequireProofLoading(false);
    }
  };

  const fetchAllPreShiftChecklistTemplatesForVendor = async (allPreShiftChecklistTemplateResponse: any) => {
    setIsPreShiftChecklistLoading(true);
    try {
      const preShiftChecklistServicesResponse = await MasterDataApiService.getMasterDataCategories(
        MasterData.PreShiftCheckList
      );
      if (preShiftChecklistServicesResponse.data?.length) {
        let modifiedData: IDataProps[] = preShiftChecklistServicesResponse.data.map(
          (preShiftChecklistServicesCategory: IDataProps) => {
            const selectedPreShiftCheckListTemplateItems = allPreShiftChecklistTemplateResponse?.find(
              (preShiftChecklistTemplateItem: PreShiftChecklistTemplate) =>
                preShiftChecklistServicesCategory.keyCode ===
                preShiftChecklistTemplateItem.mdPreShiftCheckListCategoryId
            );
            let serviceOptions = [];
            if (
              selectedPreShiftCheckListTemplateItems &&
              Object.keys(selectedPreShiftCheckListTemplateItems).length !== 0
            ) {
              serviceOptions = selectedPreShiftCheckListTemplateItems.preShiftChecklistTemplateItems.map(
                (preShiftChecklistTemplateItem: PreShiftChecklistTemplateItem) => {
                  const id = uuidv4();
                  return {
                    quantity: preShiftChecklistTemplateItem.quantity,
                    title: preShiftChecklistTemplateItem.itemName,
                    quantityName: getQuantityName(preShiftChecklistTemplateItem.mdQuantityType, 'query'),
                    id,
                    status: getStatus(preShiftChecklistTemplateItem.mdItemActionType, 'query'),
                    preShiftChecklistTemplateItemId:
                      preShiftChecklistTemplateItem?.preShiftChecklistTemplateItemId || '',
                  };
                }
              );
            }
            return {
              id: preShiftChecklistServicesCategory.keyCode,
              category: preShiftChecklistServicesCategory.keyCode,
              categoryTitle: preShiftChecklistServicesCategory.value,
              options: serviceOptions,
              headerIcon: preShiftChecklistServicesCategory.subKey1Value,
              preShiftChecklistTemplateId: selectedPreShiftCheckListTemplateItems?.preShiftChecklistTemplateId || '',
            };
          }
        );
        if (isOnlySubVendor) {
          modifiedData = modifiedData.filter((checkListItem) => !isEmpty(checkListItem?.options));
        }
        return modifiedData;
      } else {
        setSnackbarMessage(preShiftChecklistServicesResponse.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Errors', e);
    } finally {
      setIsPreShiftChecklistLoading(false);
    }
  };
  useEffect(() => {
    getWorkOrderDetails();
  }, []);

  const isShiftDatesAndAssociateShiftDatesSame = ({
    associates = [],
    shiftStartDate = '',
    shiftEndDate = '',
  }: {
    associates: WorkOrderTeamWithAssignment[];
    shiftEndDate: string;
    shiftStartDate: string;
  }) => {
    try {
      if (!isEmpty(associates)) {
        return associates?.every((item) => {
          const associateStartDate = item?.associateStartDate as string;
          const associateEndDate = item?.associateEndDate as string;
          const formattedAssociateStartDate = formatDate(associateStartDate.toString());
          const formattedAssociateEndDate = formatDate(associateEndDate.toString());
          const formattedShiftStartDate = formatDate(shiftStartDate.toString(), 'YYYY-MM-DD');
          const formattedShiftEndDate = formatDate(shiftEndDate.toString(), 'YYYY-MM-DD');

          return (
            moment(formattedAssociateStartDate).isSameOrAfter(formattedShiftStartDate) &&
            moment(formattedAssociateEndDate).isSameOrBefore(formattedShiftEndDate)
          );
        });
      }
      return false;
    } catch (error: any) {
      return false;
    }
  };

  const canVendorStartTheJob = (details: IStartJob) => {
    const {
      workOrderScheduleTeam = [],
      shiftDetails = { endTime: '', startTime: '' },
      workOrderStatus = '',
      startDate = '',
      jobCustodian = '',
      isOnlySubVendor = false,
      isSelfPerformJob = false,
    } = details;

    const filteredData = workOrderScheduleTeam.filter(
      (dataItem: WorkOrderTeam) => dataItem.workOrderId === workOrderId
    );

    const isSupervisorExist = !isEmpty(filteredData?.filter((dataItem: WorkOrderTeam) => dataItem?.isSupervisor));
    const isAssociateExist = !isEmpty(filteredData?.filter((dataItem: WorkOrderTeam) => !dataItem?.isSupervisor));
    const isUpdateJobStatus = workOrderStatus === 'Accepted';
    const { endTime = '', startTime = '' } = shiftDetails || {};

    const hasJobStarted = moment().isSameOrAfter(moment(startDate));

    return !!(
      (isEmpty(jobCustodian) && // For normal recurring and one time job
        isSupervisorExist &&
        isAssociateExist &&
        !isEmpty(endTime) &&
        !isEmpty(startTime) &&
        hasJobStarted &&
        isUpdateJobStatus) ||
      (!isEmpty(jobCustodian) && // For OTJ flow only sub vendor can start the job and prime vendor can start the job only for self perform job.
        (isOnlySubVendor || isSelfPerformJob) &&
        isSupervisorExist &&
        isAssociateExist &&
        !isEmpty(endTime) &&
        !isEmpty(startTime) &&
        hasJobStarted &&
        isUpdateJobStatus)
    );
  };

  const disableStartJob = async (details: ICanVendorStartJob) => {
    const {
      shiftTimings,
      workOrderStatus = '',
      startDate = '',
      jobCustodian = '',
      isOnlySubVendor = false,
      isSelfPerformJob = false,
    } = details;
    try {
      const allAssociatesInShiftRangeForSchedules: boolean[] = [];
      const vendorStartTheJob: boolean[] = [];
      await Promise.all(
        shiftTimings?.map(async (schedule) => {
          const filteredAssociates = schedule?.scheduleAssignments?.filter((item: any) => !item.isSupervisor);

          const isVendorStartTheJob = canVendorStartTheJob({
            workOrderScheduleTeam: schedule?.scheduleAssignments ?? [],
            shiftDetails: {
              endTime: schedule?.endTime ?? '',
              startTime: schedule?.startTime ?? '',
            },
            workOrderStatus,
            startDate,
            isSelfPerformJob,
            isOnlySubVendor,
            jobCustodian,
          });
          const isAllAssociatesInRange = isShiftDatesAndAssociateShiftDatesSame({
            associates: filteredAssociates,
            shiftStartDate: schedule?.scheduleStartDate,
            shiftEndDate: schedule?.scheduleEndDate,
          });
          allAssociatesInShiftRangeForSchedules.push(isAllAssociatesInRange);
          vendorStartTheJob.push(isVendorStartTheJob);
        })
      );
      const isAllAssociatesInShiftRange = allAssociatesInShiftRangeForSchedules?.every(
        (eachScheduleInRange) => eachScheduleInRange
      );
      const isVendorStartTheJob =
        vendorStartTheJob.length > 0 &&
        vendorStartTheJob?.every((eachScheduleCanStartByVendor) => eachScheduleCanStartByVendor);
      return { isVendorStartTheJob, isAllAssociatesInShiftRange };
    } catch (e: any) {
      return { isVendorStartTheJob: false, isAllAssociatesInShiftRange: false };
    }
  };

  const handleStartJob = async () => {
    try {
      setStartJobLoading(true);

      let workOrderResponse;
      let subWorkOrderResponse = { data: {}, errors: [] };

      if (!isOnlySubVendor) {
        workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
          workOrderId: workOrderId,
          status: WorkOrderStatus.InProgress,
        });
        if (subWorkOrderId) {
          subWorkOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
            workOrderId: subWorkOrderId,
            status: WorkOrderStatus.InProgress,
          });
        }
      } else {
        workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
          workOrderId: parentWorkOrderId,
          status: WorkOrderStatus.InProgress,
        });
        if (workOrderId) {
          subWorkOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus({
            workOrderId: workOrderId,
            status: WorkOrderStatus.InProgress,
          });
        }
      }

      if (
        subWorkOrderId
          ? !isEmpty(workOrderResponse?.data) &&
            isEmpty(workOrderResponse?.errors) &&
            !isEmpty(subWorkOrderResponse?.data) &&
            isEmpty(subWorkOrderResponse?.errors)
          : !isEmpty(workOrderResponse?.data) && isEmpty(workOrderResponse?.errors)
      ) {
        setWorkOrderStatus(workOrderResponse?.data?.updateWorkOrderStatus?.status);
        setStartJobLoading(false);
        setSnackbarMessage(t('assignJob:confirmStartJobMessage'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setStartJobOpen(!startJobOpen);
      } else {
        setStartJobLoading(false);
        setSnackbarMessage(t('Error occurred'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      setStartJobLoading(false);
      setSnackbarMessage('Error occurred');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderStartJobButton = () => {
    return (
      <>
        {workOrderStatus === WorkOrderStatus.Accepted && isVendorCanStartJob ? (
          <Box
            sx={{
              position: 'fixed',
              zIndex: 1000,
              right: '9.75rem',
              bottom: '2rem',
            }}>
            <TitleCard
              onClick={!isShiftTimingsLoading ? (isVendorCanStartJob ? handleStartJobPopup : undefined) : undefined}
              sx={{
                background: theme.palette.primary.dark,
                padding: '1.0625rem 2rem',
                borderRadius: '1rem',
                fontWeight: theme.typography.fontWeightBold,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h4?.fontSize,
                color: theme.palette.common.white,
                opacity: isShiftTimingsLoading ? 0.5 : 1,
              }}>
              {t('assignJob:startJob')}
            </TitleCard>
          </Box>
        ) : null}
      </>
    );
  };

  // filters associate with shortlisted supervisor
  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = checkedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  const fetchWorkOrderFacilityAccessInfo = async (workOrderId: string) => {
    setIsFacilityAccessLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      if (vendorId) {
        setIsLoading(true);
        const workOrderFacilityResponse = await WorkOrdersApiService.getAllWorkOrders({
          vendorId,
          isJobAccepted: true,
        });
        if (workOrderFacilityResponse.data.length) {
          const fetchFacility = workOrderFacilityResponse.data.find((value: any) => value.workOrderId === workOrderId);
          if (fetchFacility?.customerId && fetchFacility.facilities[0] && fetchFacility.facilities[0].facilityId) {
            const usersResponse = await CustomerApiService.getAllUsers(fetchFacility?.customerId);
            const facilityAccess = await CustomerApiService.getFacilityAccess(
              fetchFacility?.customerId,
              fetchFacility.facilities[0].facilityId
            );
            const facilityResponse = await FacilityApiService.facility(
              fetchFacility.facilities[0].facilityId,
              fetchFacility?.customerId
            );
            if (facilityResponse?.errors?.length) {
              setSnackbarMessage(facilityResponse?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            if (facilityAccess?.errors?.length) {
              setSnackbarMessage(facilityAccess?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            let contactPersonInfo: any = {};
            if (usersResponse?.data && facilityAccess?.data) {
              contactPersonInfo = usersResponse.data.find(
                (member: any) => member?.userId === facilityAccess.data[0]?.accessContactPerson
              );
            } else if (usersResponse.errors.length) {
              setSnackbarMessage(usersResponse?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
            const tempFacilityInfo = !isEmpty(facilityAccess?.data)
              ? facilityAccess?.data.map((facility: any) => {
                  if (facility.accessInfo === AccessOptions.KeyCard) {
                    return {
                      location: returnCompleteAddress(facilityResponse?.data?.address ?? ''),
                      code: t('facility:keyCard'),
                      parking: facility.otherInformation || t('vendor:noParkingInformationFound'),
                      contactPerson: contactPersonInfo?.username || t('vendor:noContactPersonFound'),
                      contactPersonNumber: contactPersonInfo?.phoneNumber || '',
                      accessInfo: t('facility:keyCard'),
                    };
                  }
                  return {
                    location: returnCompleteAddress(facilityResponse?.data?.address ?? ''),
                    code: facility.accessCode || t('vendor:noAccessCode'),
                    parking: facility.otherInformation || t('vendor:noParkingInformationFound'),
                    contactPerson: contactPersonInfo?.username || t('vendor:noContactPersonFound'),
                    contactPersonNumber: contactPersonInfo?.phoneNumber || '',
                    accessInfo: getAccessFacilityLockKeyLabel(facility.accessInfo) || t('vendor:noAccessInfoFound'),
                  };
                })
              : [];
            setFacilityAccessData(tempFacilityInfo || []);
          }
        } else if (workOrderFacilityResponse?.errors?.length && workOrderFacilityResponse?.errors[0]?.message) {
          setSnackbarMessage(workOrderFacilityResponse?.errors[0]?.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
        setIsFacilityAccessLoading(false);
      }
    } catch (e) {
      console.error('Errors', e);
      setIsFacilityAccessLoading(false);
    }
    setIsLoading(false);
    setIsFacilityAccessLoading(false);
  };

  const returnCompleteAddress = (address: any) => {
    // TODO: need to append just addressLine 1(facility unit) and addressLine 3(addressLine1)
    const { addressLine1, addressLine2 } = address;
    const addressLines = removeNonValueFromStringArray([addressLine1, addressLine2]);
    if (addressLines.some((line: string) => line)) {
      return addressLines.join(', ').replace(/,\s*$/, '');
    }
    return t('vendor:noLocationFound');
  };

  const renderFacilityAccessData = () => {
    return (
      <>
        <CardContainer>
          {facilityAccessData.map((data: any) => {
            const contactPersonName = `${data.contactPerson} ${
              data.contactPersonType ? `(${data.contactPersonType})` : ''
            }`;
            // const contactPersonCallName =
            //   data.contactPerson !== t('vendor:noContactPersonFound')
            //     ? `${t('common:call')} ${data.contactPerson}`
            //     : data.contactPerson;
            return (
              <>
                <AssignJobText className="TitleContainer">{t('assignJob:location')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.LocationIcon} />
                  <AssignJobText className="Name">{data?.location}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{data.accessInfo}:</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.OtpIcon} />
                  <AssignJobText className="Name">{data.code}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{t('assignJob:parkingAt')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.ParkingIcon} />
                  <AssignJobText className="Name">{data.parking}</AssignJobText>
                </div>
                <AssignJobText className="TitleContainer">{t('assignJob:contactPerson')}</AssignJobText>
                <div className="contentFlex">
                  <img src={Icons.AccountIcon} />
                  <AssignJobText className="Name">{contactPersonName}</AssignJobText>
                </div>
              </>
            );
          })}
        </CardContainer>
      </>
    );
  };

  const getTransformedServiceAndTasksData = (services: ServicesWithTasks[]): IVendorProofOfCompletion[] => {
    return (
      services?.map((service: ServicesWithTasks): IVendorProofOfCompletion => {
        const totalProofRequiredTasks =
          service?.tasks?.filter((task) => task?.isProofOfCompletionRequired === WorkOrderYesNoType.Yes) || [];

        const tasks =
          service?.tasks?.map((task) => {
            return {
              id: task?.woScheduleServiceId ?? '',
              title: task?.mdTaskName ?? '',
              floorNumber: 'All',
              isProofOfCompletionRequired: task?.isProofOfCompletionRequired,
              taskScheduleId: task?.woScheduleServiceId ?? '',
            };
          }) || [];

        return {
          id: service?.mdServiceId ?? '',
          category: 'floors',
          categoryTitle: service?.mdServiceName ?? '',
          totalTasks: service?.tasks?.length || 0,
          totalProofRequiredTasks: totalProofRequiredTasks?.length,
          options: tasks,
          isSelectAll: tasks.every((task) => task.isProofOfCompletionRequired === WorkOrderYesNoType.Yes),
        };
      }) || []
    );
  };

  const renderTasksRequireProofData = (proofOfCompletionData: any) => {
    const newProofOfCompletionData = proofOfCompletionData?.transformedServiceAndTaskData;
    return (
      <>
        {newProofOfCompletionData?.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {newProofOfCompletionData?.map((service: any, index: number) => {
              const tasksWithProofRequired = service?.options
                .filter((task: any) => task?.isProofOfCompletionRequired === 'Yes')
                .map((task: any) => task?.title)
                .join(', ');
              return (
                <AssignJobText key={index}>
                  {service.categoryTitle} -{' '}
                  {tasksWithProofRequired ? tasksWithProofRequired : t('assignJob:noTasksSelectedYet')}
                </AssignJobText>
              );
            })}
          </Box>
        ) : (
          <AssignJobText>{t('assignJob:noDataYet')}</AssignJobText>
        )}
      </>
    );
  };

  function isPreShiftChecklistTemplateItemActionTypeNew(
    value: any
  ): value is PreShiftChecklistTemplateItemActionTypeNew {
    return Object.values(PreShiftChecklistTemplateItemActionTypeNew).includes(value);
  }

  const getStatus = useCallback(
    (status: PreShiftChecklistTemplateItemActionType, type = '') => {
      if (type === 'query') {
        switch (status) {
          case PreShiftChecklistTemplateItemActionType.Brought:
            return t('vendor:toBeBrought');
          case PreShiftChecklistTemplateItemActionType.PickedUp:
            return t('vendor:buyFromBestBuy');
          case PreShiftChecklistTemplateItemActionType.Provided:
            return providedText;
          default:
            return providedText;
        }
      } else {
        if (isPreShiftChecklistTemplateItemActionTypeNew(status)) {
          switch (status) {
            case PreShiftChecklistTemplateItemActionTypeNew.Brought:
              return PreShiftChecklistTemplateItemActionType.Brought;
            case PreShiftChecklistTemplateItemActionTypeNew.PickedUp:
              return PreShiftChecklistTemplateItemActionType.PickedUp;
            case PreShiftChecklistTemplateItemActionTypeNew.Provided:
              return PreShiftChecklistTemplateItemActionType.Provided;
            default:
              return PreShiftChecklistTemplateItemActionType.Provided;
          }
        } else {
          console.error('Invalid status');
          return PreShiftChecklistTemplateItemActionType.Provided;
        }
      }
    },
    [t]
  );
  const getQuantityName = useCallback(
    (quantityName: string, type = '') => {
      if (type === 'query') {
        switch (quantityName) {
          case PreShiftChecklistTemplateItemQuantityType.Unit.toString():
            return t('common:unit');
          case PreShiftChecklistTemplateItemQuantityType.KG.toString():
            return t('common:kg');
          case PreShiftChecklistTemplateItemQuantityType.Litre.toString():
            return t('common:litre');
          case PreShiftChecklistTemplateItemQuantityType.Pair.toString():
            return t('common:pair');
          case PreShiftChecklistTemplateItemQuantityType.Rolls.toString():
            return t('common:rolls');
          default:
            return undefined;
        }
      } else {
        switch (quantityName) {
          case t('common:unit'):
            return PreShiftChecklistTemplateItemQuantityType.Unit;
          case t('common:kg'):
            return PreShiftChecklistTemplateItemQuantityType.KG;
          case t('common:litre'):
            return PreShiftChecklistTemplateItemQuantityType.Litre;
          case t('common:pair'):
            return PreShiftChecklistTemplateItemQuantityType.Pair;
          case t('common:rolls'):
            return PreShiftChecklistTemplateItemQuantityType.Rolls;
          default:
            return undefined;
        }
      }
    },
    [t]
  );

  const renderAddedItem = (catalogueId: string, items: any[], shift: any) => {
    return (
      <>
        {items.map((item: any, index: number) => {
          return (
            <>
              <AssignJobText key={index} sx={{ display: 'flex', marginBottom: '0.5em' }}>
                <li style={{ maxWidth: '20em', width: '20em', marginLeft: '1em' }}>
                  {item.title} - {item.quantity} {item.quantityName} - {t(`assignJob:${item.status}`)}
                </li>
                {workOrderStatus === WorkOrderStatus.Accepted &&
                  ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
                  !isAssociatePunchedIn && (
                    <Box onClick={() => handleAddItemEdit(catalogueId, item, shift)} sx={{ cursor: 'pointer' }}>
                      <img
                        src={Icons.EditBlackIcon}
                        alt="Edit"
                        style={{ width: '1.5rem', height: '1.5rem', marginRight: '2em' }}
                      />
                    </Box>
                  )}
              </AssignJobText>
            </>
          );
        })}
      </>
    );
  };

  const renderAssignedSupervisorTeamData = (supervisors: any) => {
    if (!supervisors.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }} key={shiftDetailId}>
          <AssignJobText>{t('assignJob:supervisorsAreNotAssignedYet')}</AssignJobText>
        </div>
      );
    }
    const assignedSupervisors = supervisors
      .filter((member: any) => member.isChecked)
      .map((member: any) => member.name)
      .join(', ');

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} key={shiftDetailId}>
        <AssignJobText>
          {t('assignJob:assignedSupervisors')}{' '}
          {assignedSupervisors ? assignedSupervisors : t('assignJob:supervisorsAreNotAssignedYet')}
        </AssignJobText>
      </div>
    );
  };

  const renderAssignedAssociateTeamData = (associates: any) => {
    if (!associates.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }} key={shiftDetailId}>
          <AssignJobText>{t('assignJob:associatesAreNotAssignedYet')}</AssignJobText>
        </div>
      );
    }
    const assignedAssociates = associates
      .filter((member: any) => member.isChecked)
      .map((member: any) => member.name)
      .join(', ');
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} key={shiftDetailId}>
        {isSelfPerformJob ||
        (role === UserType.Vendor && !isDistrictManager) ||
        jobData?.jobDetails?.jobCustodian === null ? (
          <AssignJobText>
            {t('assignJob:assignedAssociates')}{' '}
            {assignedAssociates ? assignedAssociates : t('assignJob:associatesAreNotAssignedYet')}
          </AssignJobText>
        ) : null}
      </div>
    );
  };

  // const renderShiftDetailsData = () => {
  //   return (
  //     <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
  //       {shiftTimings.length > 0 ? (
  //         <>
  //           {shiftTimings.map((shiftTiming: ShiftDetails, index: number) => {
  //             return (
  //               <div key={index} style={{ display: 'flex', gap: '4em' }}>
  // {!(workOrderData.jobType === JobType.Recurring) && (
  //   <AssignJobText>
  //     {t('assignJob:shiftDate')}:{' '}
  //     {formatDate(
  //       workOrderData.actualStartDate ? workOrderData.actualStartDate : workOrderData.jobStartDate,
  //       'MM/DD/YYYY'
  //     )}{' '}
  //     -{' '}
  //     {formatDate(
  //       workOrderData.actualEndDate ? workOrderData.actualEndDate : workOrderData.jobEndDate,
  //       'MM/DD/YYYY'
  //     )}
  //   </AssignJobText>
  // )}

  //                 <AssignJobText>
  //                   {t('assignJob:shiftTime')}: {getTimeFormat(shiftTiming.startTime)} -{' '}
  //                   {getTimeFormat(shiftTiming.endTime)}
  //                 </AssignJobText>
  //                 {workOrderStatus === WorkOrderStatus.Accepted &&
  //                   ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Customer)) &&
  //                   !isAssociatePunchedIn && (
  //                     <Button
  //                       onClick={() => {
  //                         if (workOrderData.workOrderTeams && !isEmpty(workOrderData.workOrderTeams)) {
  //                           setSelectedShiftId(shiftTiming.shiftDetailId);
  //                           setOpenDeleteShiftWarning(true);
  //                         } else {
  //                           deleteShift(shiftTiming.shiftDetailId);
  //                         }
  //                       }}
  //                       sx={{
  //                         backgroundColor: 'transparent',
  //                         padding: 0,
  //                         margin: 0,
  //                         minWidth: 0,
  //                       }}>
  //                       {isShiftDeleting ? (
  //                         <CircularProgress size={30} sx={{ color: theme.palette.common.black }} />
  //                       ) : (
  //                         <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
  //                       )}
  //                     </Button>
  //                   )}
  //               </div>
  //             );
  //           })}
  //         </>
  //       ) : (
  //         <AssignJobText>{t('assignJob:noDataAddedYet')}</AssignJobText>
  //       )}
  //     </Box>
  //   );
  // };

  const onSave = useCallback(
    async (shiftTimings: IShiftTimings[], details: IShiftDetails, sectionTitle: string) => {
      setIsPrimaryButtonLoading(true);

      const jobStartDate = formatDate(jobData?.jobDetails?.startDate, 'YYYY-MM-DD');
      const jobEndDate = formatDate(jobData?.jobDetails?.stopDate, 'YYYY-MM-DD');

      const modifiedJobStartDate = moment(jobStartDate).format('YYYY-MM-DDT00:00:00.000');
      const modifiedJobEndDate = moment(jobEndDate).format('YYYY-MM-DDT00:00:00.000');

      const modifiedStartDate = modifiedJobStartDate;

      const modifiedEndDate = modifiedJobEndDate;

      const scheduleStartDate =
        jobData?.jobDetails?.jobType === JobType.Recurring
          ? modifiedJobStartDate
          : moment(details.scheduleStartDate).format('YYYY-MM-DDT00:00:00.000');
      const scheduleEndDate =
        jobData?.jobDetails?.jobType === JobType.Recurring
          ? modifiedJobEndDate
          : moment(details.scheduleEndDate).format('YYYY-MM-DDT00:00:00.000');
      try {
        const combinedShiftTiming = shiftTimings;

        const mapShiftTimings: UpdateShiftDetailsInput[] = combinedShiftTiming.map((shift: IShiftTimings) => ({
          startTime: `${shift.startTime.hours}:${shift.startTime.minutes}`,
          endTime: `${shift.endTime.hours}:${shift.endTime.minutes}`,
          shiftDetailId: shift.shiftDetailId || '',
          scheduleStartDate,
          scheduleEndDate,
          endDateOffset: Number(details?.offsetDays ?? 0),
        }));

        const selectedParentSchedule = parentSchedules?.find(
          (parentSchedule) => parentSchedule?.scheduleName === sectionTitle
        );
        const uniqueShiftTiming: UpdateShiftDetailsInput[] = mapShiftTimings.reduce(
          (acc: UpdateShiftDetailsInput[], current: UpdateShiftDetailsInput) => {
            const matchedTime = acc.find(
              (item: UpdateShiftDetailsInput) =>
                item?.startTime === current?.startTime && item?.endTime === current?.endTime
            );
            if (!matchedTime) {
              return acc.concat([current]);
            }
            return acc;
          },
          []
        );
        let isFulfillmentDateUpdate = false;

        if (!isOtjJob) {
          const payloadForRecurringJob = {
            workOrderId,
            actualStartDate: modifiedStartDate,
            actualEndDate: modifiedEndDate,
            jobStartDate: modifiedStartDate,
            jobEndDate: modifiedEndDate,
          };
          const payload = {
            workOrderId,
            actualStartDate: modifiedStartDate,
            actualEndDate: modifiedEndDate,
            jobStartDate: modifiedStartDate,
            jobEndDate: modifiedEndDate,
          };
          const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(
            jobData?.jobDetails?.jobType === JobType.Recurring ? payloadForRecurringJob : payload
          );
          if (!isEmpty(workOrderResponse?.errors ?? [])) {
            setSnackbarMessage(workOrderResponse?.errors[0]?.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          } else {
            isFulfillmentDateUpdate = true;
          }
        } else {
          if ((isDistrictManager && (isOnlyPrimeVendor || isSelfPerformJob)) || isOnlySubVendor) {
            if (subWorkOrderId) {
              const payload: UpdateWorkOrderInput = {
                workOrderId: subWorkOrderId,
                actualStartDate: modifiedStartDate,
                actualEndDate: modifiedEndDate,
                jobStartDate: modifiedStartDate,
                jobEndDate: modifiedEndDate,
              };
              const workOrderResponseForSubJob = await WorkOrdersApiService.updateWorkOrderStatus(payload);
              if (!isEmpty(workOrderResponseForSubJob?.errors ?? [])) {
                setSnackbarMessage(workOrderResponseForSubJob?.errors[0]?.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
              } else {
                const mainWorkOrderPayload = {
                  workOrderId,
                  actualStartDate: modifiedStartDate,
                  actualEndDate: modifiedEndDate,
                  jobStartDate: modifiedStartDate,
                  jobEndDate: modifiedEndDate,
                };
                const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(mainWorkOrderPayload);
                if (!isEmpty(workOrderResponse?.errors ?? [])) {
                  setSnackbarMessage(workOrderResponse?.errors[0]?.message);
                  setSnackbarSeverity('error');
                  setSnackbarOpen(true);
                } else {
                  isFulfillmentDateUpdate = true;
                }
              }
            } else {
              const payload: UpdateWorkOrderInput = {
                workOrderId,
                actualStartDate: modifiedStartDate,
                actualEndDate: modifiedEndDate,
                jobStartDate: modifiedStartDate,
                jobEndDate: modifiedEndDate,
              };
              const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(payload);
              if (!isEmpty(workOrderResponse?.errors ?? [])) {
                isFulfillmentDateUpdate = false;
                setSnackbarMessage(workOrderResponse?.errors[0]?.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
              } else {
                isFulfillmentDateUpdate = true;
              }
            }
          }
        }
        if (subWorkOrderId) {
          const addedShiftTimingResponseForSubJob = await WorkOrdersApiService.updateShiftDetails(uniqueShiftTiming);
          if (
            !isEmpty(addedShiftTimingResponseForSubJob?.data ?? []) &&
            isEmpty(addedShiftTimingResponseForSubJob?.errors ?? [])
          ) {
            const parentUniqueShiftTiming = uniqueShiftTiming?.map((schedule) => {
              return {
                ...schedule,
                shiftDetailId: selectedParentSchedule?.shiftDetailId ?? '',
              };
            });
            const addedShiftTimingResponse = await WorkOrdersApiService.updateShiftDetails(parentUniqueShiftTiming);
            if (!isEmpty(addedShiftTimingResponse?.data ?? []) && isEmpty(addedShiftTimingResponse?.errors ?? [])) {
              await fetchScheduleDetails(subWorkOrderId ? subWorkOrderId : workOrderId);
              setSnackbarMessage('Successfully updated the shift');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
            } else {
              setSnackbarMessage(addedShiftTimingResponse?.errors[0]?.message);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
          } else {
            setSnackbarMessage(addedShiftTimingResponseForSubJob?.errors[0]?.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } else {
          const addedShiftTimingResponse = await WorkOrdersApiService.updateShiftDetails(uniqueShiftTiming);
          if (
            !isEmpty(addedShiftTimingResponse?.data ?? []) &&
            isFulfillmentDateUpdate &&
            isEmpty(addedShiftTimingResponse?.errors ?? [])
          ) {
            await fetchScheduleDetails(subWorkOrderId ? subWorkOrderId : workOrderId);
            setSnackbarMessage('Successfully updated the shift');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          } else if (!isEmpty(addedShiftTimingResponse?.errors ?? [])) {
            setSnackbarMessage(addedShiftTimingResponse?.errors[0]?.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        }
      } catch (e) {
        console.error('Error', e);
      } finally {
        setIsPrimaryButtonLoading(false);
      }
    },
    [
      isDistrictManager,
      isOtjJob,
      isOnlyPrimeVendor,
      isOnlySubVendor,
      isSelfPerformJob,
      jobData.jobType,
      jobData?.jobDetails?.stopDate,
      jobData?.jobDetails?.startDate,
      subWorkOrderId,
      t,
      workOrderId,
      fetchScheduleDetails,
      setIsPrimaryButtonLoading,
    ]
  );

  const handleAddItemClose = () => {
    setAddItemCardOpen(false);
    setAddItemData({});
  };

  const [hoursList, setHoursList] = useState<any[]>([]);

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
  }, []);

  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState('');
  const [selectedShift, setSelectedShift] = useState({});

  const handleDialogOpen = (shift: any) => {
    setSelectedShiftId(shift?.shiftDetailId);
    setSelectedShift(shift);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedShiftId('');
  };

  const handleAddItem = (addedItem: any, isEdit: boolean) => {
    if (!isEdit) {
      let alreadyExist = false;
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions = [];
          catalogue.options?.forEach((item: any) => {
            if (item?.title?.toLowerCase() === addedItem?.title?.toLowerCase()) {
              alreadyExist = true;
              tempOptions.push(addedItem);
            } else {
              tempOptions.push(item);
            }
          });
          if (!alreadyExist) {
            tempOptions.push(addedItem);
          }
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }
        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
      handleSaveChecklist(updatedChecklistData);
    } else {
      const updatedChecklistData = checkListData.map((catalogue: any) => {
        const tempCatalogue = { ...catalogue };
        if (catalogue.id === selectedCatalogue) {
          const tempOptions: any[] = [];
          catalogue?.options?.forEach((item: any) => {
            if (item.id === addedItem.id) {
              return tempOptions.push({ ...addedItem });
            }

            return tempOptions.push(item);
          });
          tempCatalogue.options = tempOptions;
          return tempCatalogue;
        }

        return tempCatalogue;
      });
      setCheckListData(updatedChecklistData);
      handleSaveChecklist(updatedChecklistData);
    }
    handleAddItemClose();
  };

  const handleAddItemDelete = (itemForDeletion: any) => {
    const updatedAfterDeletion: IDataProps[] = checkListData.map((catalogue: any) => {
      const tempOptions = { ...catalogue };
      if (catalogue.id === selectedCatalogue) {
        tempOptions.options = catalogue.options.filter((item: any) => {
          return !(item.id === itemForDeletion.id);
        });
        return tempOptions;
      }
      return catalogue;
    });
    setCheckListData(updatedAfterDeletion);
    setSelectedCatalogue('');
    handleAddItemClose();
    handleSaveChecklist(updatedAfterDeletion);
  };

  const handleAddItemEdit = (catalogueId: string, item: any, shift: any) => {
    setCheckListData(shift?.preShiftCheckList);
    setSelectedCatalogue(catalogueId || '');
    setAddItemData(item);
    setAddItemCardOpen(true);
  };

  useEffect(() => {
    if (isEmpty(addItemData)) {
      setSelectedCatalogue(checkListData[0]?.id as string);
    }
  }, [checkListData, addItemData]);

  const handleAddItemButton = (shift: any) => {
    setCheckListData(shift?.preShiftCheckList);
    setAddItemCardOpen(true);
    setSelectedScheduleId(shift?.shiftDetailId);
  };

  const handleSaveChecklist = (checkListData: IDataProps[]) => {
    if (isAssociate) {
      // submitChecklist();
    } else if (isVendor) {
      savePreShiftChecklist(checkListData);
    }
  };

  const savePreShiftChecklist = async (checkListData: IDataProps[]) => {
    const updateChecklistData =
      checkListData
        ?.filter((checkList) => !isEmpty(checkList?.preShiftChecklistTemplateId))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem: any) => {
            const serviceTask: any = {
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status),
              quantity: optionItem.quantity,
              mdQuantityType: getQuantityName(optionItem.quantityName as string),
            };
            if (!isEmpty(optionItem?.preShiftChecklistTemplateItemId)) {
              serviceTask.preShiftChecklistTemplateItemId = optionItem?.preShiftChecklistTemplateItemId;
            }
            items.push(serviceTask);
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
            preShiftChecklistTemplateId: item?.preShiftChecklistTemplateId,
          };
        }) || [];

    const addNewChecklistData =
      checkListData
        ?.filter((checkList) => isEmpty(checkList?.preShiftChecklistTemplateId || ''))
        ?.map((item) => {
          const items: PreShiftChecklistTemplateItemInput[] = [];
          item.options?.forEach((optionItem: any) => {
            items.push({
              itemName: optionItem.title,
              mdItemActionType: getStatus(optionItem.status) as PreShiftChecklistTemplateItemActionType,
              quantity: optionItem.quantity as number,
              mdQuantityType: getQuantityName(
                optionItem.quantityName as string
              ) as PreShiftChecklistTemplateItemQuantityType,
            });
          });
          return {
            checkListName: item.categoryTitle,
            checkListIconUrl: item.headerIcon,
            mdPreShiftCheckListCategoryId: item.id,
            buildingAccessConfirmation: WorkOrderYesNoType.Yes,
            securityAccessConfirmation: WorkOrderYesNoType.Yes,
            safetyConfirmation: WorkOrderYesNoType.Yes,
            msdsChemicalSafety: WorkOrderYesNoType.Yes,
            workStatus: WorkOrderStatus.Created,
            preShiftChecklistTemplateItems: items?.length ? items : [],
          };
        }) || [];

    try {
      setIsPreShiftChecklistLoading(true);
      if (!isEmpty(addNewChecklistData)) {
        if (subWorkOrderId) {
          await Promise.all(
            addNewChecklistData.map(async (checkListItem) => {
              return await WorkOrdersApiService.createPreShiftChecklistTemplate({
                workOrderId: subWorkOrderId,
                createPreShiftChecklistTemplateInput: checkListItem as PreShiftChecklistTemplateInput,
                woScheduleMasterId: selectedScheduleId,
              });
            })
          )
            .then((data) => {
              setSnackbarMessage(updatedPreShiftChecklistText);
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        } else {
          await Promise.all(
            addNewChecklistData.map((checkListItem) => {
              return WorkOrdersApiService.createPreShiftChecklistTemplate({
                workOrderId: workOrderId,
                createPreShiftChecklistTemplateInput: checkListItem as PreShiftChecklistTemplateInput,
                woScheduleMasterId: selectedScheduleId,
              }).then((data) => data);
            })
          )
            .then((data) => {
              setSnackbarMessage(updatedPreShiftChecklistText);
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              return data;
            })
            .catch((e) => {
              setSnackbarMessage(e);
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        }
      }
      if (!isEmpty(updateChecklistData)) {
        await Promise.all(
          updateChecklistData.map((checkListItem) => {
            return WorkOrdersApiService.updatePreShiftChecklistTemplate(
              checkListItem?.preShiftChecklistTemplateId as string,
              checkListItem as PreShiftChecklistTemplateInput
            ).then((data) => data);
          })
        )
          .then((data) => {
            setSnackbarMessage(updatedPreShiftChecklistText);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            return data;
          })
          .catch((e) => {
            setSnackbarMessage(e);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          });
      }
      await fetchScheduleDetails(subWorkOrderId ? subWorkOrderId : workOrderId);
    } catch (e) {
      console.error('Error', e);
    } finally {
      setIsPreShiftChecklistLoading(false);
    }
  };

  const handleAddOrEditAssignTeam = (shift: any, type: AssignmentCardType) => {
    if (type === AssignmentCardType.AssignSupervisor) {
      handleAddOrEditSupervisorAssignTeam(shift);
    } else if (type === AssignmentCardType.AssignAssociate) {
      handleAddOrEditAssociateAssignTeam(shift);
    }
  };

  const handleAddOrEditSupervisorAssignTeam = (shift: any) => {
    setOriginalTeamData(shift?.scheduleSupervisors?.teamData);
    setTeamData(shift?.scheduleSupervisors?.teamData);
    setCheckedSupervisors(shift?.scheduleSupervisors?.checkedTeam);
    setSelectedScheduleId(shift?.shiftDetailId);

    const isShiftTimingMissing = !shift?.startTime || !shift?.endTime;
    const isScheduleDateMissing = !shift?.scheduleStartDate || !shift?.scheduleEndDate;

    if (jobData?.jobDetails?.jobType !== JobType.Recurring) {
      if (isShiftTimingMissing || isScheduleDateMissing) {
        setOpenShiftTimingsCheck(true);
        setShiftDetailId(shift?.shiftDetailId);
      } else {
        handleOnClick(AssignmentCardType.AssignSupervisor, shift);
      }
    } else {
      if (isShiftTimingMissing) {
        setOpenShiftTimingsCheck(true);
      } else {
        handleOnClick(AssignmentCardType.AssignSupervisor, shift);
      }
    }
  };

  const handleAddOrEditAssociateAssignTeam = (shift: any) => {
    setOriginalAssociateTeamData(shift?.scheduleAssociates?.teamData);
    setAssociateTeamData(shift?.scheduleAssociates?.teamData);
    setCheckedAssociates(shift?.scheduleAssociates?.checkedTeam);
    setSelectedScheduleId(shift?.shiftDetailId);

    const isShiftTimingMissing = !shift?.startTime || !shift?.endTime;
    const isScheduleDateMissing = !shift?.scheduleStartDate || !shift?.scheduleEndDate;

    if (jobData?.jobDetails?.jobType !== JobType.Recurring) {
      if (isShiftTimingMissing || isScheduleDateMissing) {
        setOpenShiftTimingsCheck(true);
        setShiftDetailId(shift?.shiftDetailId);
      } else {
        handleOnClick(AssignmentCardType.AssignAssociate, shift);
      }
    } else {
      if (isShiftTimingMissing) {
        setOpenShiftTimingsCheck(true);
      } else {
        handleOnClick(AssignmentCardType.AssignAssociate, shift);
      }
    }
  };

  const fetchDisableStartJobResult = async () => {
    if (subWorkOrderId || workOrderId) {
      const payload = hasChild ? subWorkOrderId : workOrderId;

      if (shiftTimings?.length) {
        const { isAllAssociatesInShiftRange = false, isVendorStartTheJob = false } = await disableStartJob({
          shiftTimings,
          workOrderId: payload,
          workOrderStatus: workOrderStatus,
          startDate: jobData?.jobDetails?.startDate ?? '',
          jobCustodian: jobData?.jobDetails?.jobCustodian,
          isOnlySubVendor,
          isSelfPerformJob,
        });

        if (isVendorStartTheJob) {
          setIsVendorCanStartJob(true);
        } else {
          setIsVendorCanStartJob(false);
        }
        if (isAllAssociatesInShiftRange) {
          setOpenStartJobWarning(false);
          setDisableStartJobResult(!isVendorStartTheJob);
        } else {
          setDisableStartJobResult(true);
          setDisableStartJobResult(isVendorStartTheJob);
        }
      }
    }
  };

  useEffect(() => {
    fetchDisableStartJobResult();
  }, [isOnlySubVendor, subWorkOrderId, workOrderId, hasChild, workOrderStatus, shiftTimings]);

  return (
    <>
      {isShiftTimingsLoading ? (
        <LoaderTag>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </LoaderTag>
      ) : (
        shiftTimings.map((shift, index) => {
          return (
            <>
              <ShiftDetailsContainer key={shift.shiftDetailId} sx={{ width: index > 1 ? '50%' : '100%' }}>
                <DetailsContainer>
                  <AwardJobText>
                    <AwardJobText>{shift.scheduleName ? shift.scheduleName : `Schedule #${index + 1}`}</AwardJobText>
                  </AwardJobText>
                </DetailsContainer>
                <Divider sx={divider} />
                <TimeAndInstructions>
                  <Match>
                    <MatchText>
                      {t('assignJob:shiftTimingsText')} <span style={{ color: '#E51717' }}>&nbsp;*</span>
                    </MatchText>
                  </Match>
                  <AddDataContainer>
                    <Box>
                      {isShiftTimingsLoading ? (
                        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                      ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                          {jobData.jobDetails.jobType !== JobType.Recurring && (
                            <AssignJobText>
                              {t('assignJob:shiftDate')}:{' '}
                              {shift?.scheduleStartDate && formatDate(shift?.scheduleStartDate, 'MM/DD/YYYY')}
                              {' - '}
                              {shift?.scheduleEndDate && formatDate(shift?.scheduleEndDate, 'MM/DD/YYYY')}
                              {isEmpty(shift?.scheduleStartDate) && isEmpty(shift?.scheduleEndDate)
                                ? t('assignJob:notAvailable')
                                : null}
                            </AssignJobText>
                          )}
                          <AssignJobText>
                            {t('assignJob:shiftTime')}:{' '}
                            {shift.startTime && shift.endTime
                              ? `${getTimeFormat(shift.startTime)} - ${getTimeFormat(shift.endTime)}`
                              : 'NA - NA'}
                          </AssignJobText>
                          {shift.endDateOffset !== null && (
                            <AssignJobText>
                              {t('assignJob:shiftEndsWith')} {shift.endDateOffset}-{t('assignJob:dayOffset')}
                            </AssignJobText>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      {shiftTimings?.length &&
                        workOrderStatus === WorkOrderStatus.Accepted &&
                        ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
                        !isAssociatePunchedIn && (
                          <>
                            <Button
                              onClick={() => handleDialogOpen(shift)}
                              sx={{
                                backgroundColor: 'transparent',
                                padding: 0,
                                margin: 0,
                                minWidth: 0,
                              }}>
                              {isEmpty(shift?.startTime) && isEmpty(shift?.endTime) ? (
                                <img
                                  src={Icons.AddTagIcon}
                                  alt={addText}
                                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                                />
                              ) : (
                                <img
                                  src={Icons.EditBlackIcon}
                                  alt={addText}
                                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                                />
                              )}
                            </Button>

                            {selectedShiftId && (
                              <ShiftTimeSelection
                                jobData={jobData}
                                workOrderData={workOrderData}
                                isDialogOpen={isDialogOpen}
                                handleDialogClose={handleDialogClose}
                                handleDialogOpen={() => handleDialogOpen(selectedShift)}
                                shift={selectedShift}
                                shiftId={selectedShiftId}
                                hoursList={hoursList}
                                setStartTimeSelectOpen={setStartTimeSelectOpen}
                                setEndTimeSelectOpen={setEndTimeSelectOpen}
                                isStartTimeSelectOpen={isStartTimeSelectOpen}
                                isEndTimeSelectOpen={isEndTimeSelectOpen}
                                buttonStyles={buttonStyles}
                                t={t}
                                theme={theme}
                                onSave={onSave}
                                shiftDetailId={shift.shiftDetailId}
                                sectionTitle={shift.scheduleName}
                                shiftTimings={shiftTimings}
                                isPrimaryButtonLoading={isPrimaryButtonLoading}
                                shiftDetails={[
                                  {
                                    startTime: shift.startTime,
                                    endTime: shift.endTime,
                                    shiftEndDate: shift.workOrder.jobEndDate ?? '',
                                    shiftStartDate: shift.workOrder.jobStartDate ?? '',
                                  },
                                ]}
                              />
                            )}
                          </>
                        )}
                    </Box>
                  </AddDataContainer>
                </TimeAndInstructions>
                <Divider sx={divider} />
                <TimeAndInstructions key={shift.shiftDetailId}>
                  <Match>
                    <MatchText>
                      {t('assignJob:assignSupervisors')}
                      {((isDistrictManager && !isOnlyPrimeVendor && !workOrderData?.isInspectionRequiredForParentJob) ||
                        (!isDistrictManager &&
                          role === UserType.Vendor &&
                          !workOrderData?.isInspectionRequiredForParentJob)) && (
                        <span style={{ color: '#E51717' }}>&nbsp;*</span>
                      )}
                    </MatchText>
                  </Match>
                  <AddDataContainer>
                    <Box>
                      {isAssignSupervisorLoading ? (
                        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                      ) : (
                        <>
                          {shift?.scheduleSupervisors?.checkedTeam?.length > 0 ? (
                            renderAssignedSupervisorTeamData(shift?.scheduleSupervisors?.checkedTeam)
                          ) : (
                            <AssignJobText>{t('assignJob:teamIsNotAssignedYet')}</AssignJobText>
                          )}
                        </>
                      )}
                    </Box>
                    <Box>
                      {workOrderStatus === WorkOrderStatus.Accepted && !isAssociatePunchedIn && (
                        <Button
                          onClick={() => {
                            handleAddOrEditAssignTeam(shift, AssignmentCardType.AssignSupervisor);
                            setSelectedShift(shift);
                          }}
                          sx={{
                            backgroundColor: 'transparent',
                            padding: 0,
                            margin: 0,
                            minWidth: 0,
                          }}>
                          {shift?.scheduleSupervisors?.checkedTeam?.length > 0 ? (
                            <img
                              src={Icons.EditBlack}
                              alt={t('altTexts:edit')}
                              style={{ marginRight: '2em', width: '1.5rem', height: '1.5rem' }}
                            />
                          ) : (
                            <img
                              src={Icons.AddTagIcon}
                              alt={addText}
                              style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                            />
                          )}
                        </Button>
                      )}
                    </Box>
                  </AddDataContainer>
                </TimeAndInstructions>
                <Divider sx={divider} />
                {((isDistrictManager && !isOnlyPrimeVendor) || (!isDistrictManager && role === UserType.Vendor)) && (
                  <>
                    <TimeAndInstructions>
                      <Match>
                        <MatchText>
                          {t('assignJob:assignAssociates')}
                          <span style={{ color: '#E51717' }}>&nbsp;*</span>
                        </MatchText>
                      </Match>
                      <AddDataContainer>
                        <Box>
                          <Box>
                            {isAssignAssociateLoading ? (
                              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                            ) : (
                              <>
                                {shift?.scheduleAssociates?.checkedTeam?.length > 0 ? (
                                  renderAssignedAssociateTeamData(shift?.scheduleAssociates?.checkedTeam)
                                ) : (
                                  <AssignJobText>{t('assignJob:teamIsNotAssignedYet')}</AssignJobText>
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                        <Box>
                          {workOrderStatus === WorkOrderStatus.Accepted && !isAssociatePunchedIn && (
                            <Button
                              onClick={() => {
                                handleAddOrEditAssignTeam(shift, AssignmentCardType.AssignAssociate);
                                setSelectedShift(shift);
                              }}
                              sx={{
                                backgroundColor: 'transparent',
                                padding: 0,
                                margin: 0,
                                minWidth: 0,
                              }}>
                              {shift?.scheduleAssociates?.checkedTeam?.length > 0 ? (
                                <img
                                  src={Icons.EditBlack}
                                  alt={t('altTexts:edit')}
                                  style={{ marginRight: '2em', width: '1.5rem', height: '1.5rem' }}
                                />
                              ) : (
                                <img
                                  src={Icons.AddTagIcon}
                                  alt={addText}
                                  style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                                />
                              )}
                            </Button>
                          )}
                        </Box>
                      </AddDataContainer>
                    </TimeAndInstructions>
                    <Divider sx={divider} />
                  </>
                )}
                <TimeAndInstructions>
                  <Match>
                    <MatchText>{t('assignJob:facilityAccessText')}</MatchText>
                  </Match>
                  <Box>
                    {isFacilityAccessLoading ? (
                      <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                    ) : (
                      <>
                        {facilityAccessData.length > 0 ? (
                          renderFacilityAccessData()
                        ) : (
                          <AssignJobText>{t('assignJob:noFacilityAccessDataFound')}</AssignJobText>
                        )}
                      </>
                    )}
                  </Box>
                </TimeAndInstructions>
                <Divider sx={divider} />
                <TimeAndInstructions>
                  <Match>
                    <MatchText>{t('assignJob:tasksRequiringProofOfCompletion')}</MatchText>
                  </Match>
                  <AddDataContainer>
                    <Box>
                      {isTaskRequireProofLoading ? (
                        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                      ) : (
                        renderTasksRequireProofData(shift?.tasksProofOfCompletion)
                      )}
                    </Box>
                    <Box>
                      {workOrderStatus === WorkOrderStatus.Accepted &&
                        ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
                        !isAssociatePunchedIn && (
                          <Button
                            onClick={() => handleOnClick(AssignmentCardType.ProofOfCompletionTasks, shift)}
                            sx={{
                              backgroundColor: 'transparent',
                              padding: 0,
                              margin: 0,
                              minWidth: 0,
                            }}>
                            {shift?.tasksProofOfCompletion?.transformedServiceAndTaskData?.some(
                              (item: { options: any[] }) =>
                                item.options.some(
                                  (option: any) => option.isProofOfCompletionRequired === WorkOrderYesNoType.Yes
                                )
                            ) ? (
                              <img
                                src={Icons.EditBlack}
                                alt={t('altTexts:edit')}
                                style={{ marginRight: '2em', width: '1.5rem', height: '1.5rem' }}
                              />
                            ) : (
                              <img
                                src={Icons.AddTagIcon}
                                alt={addText}
                                style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                              />
                            )}
                          </Button>
                        )}
                    </Box>
                  </AddDataContainer>
                </TimeAndInstructions>
                <Divider sx={divider} />
                <TimeAndInstructions>
                  <Match>
                    <MatchText>{t('assignJob:PreShiftChecklist')}</MatchText>
                  </Match>
                  <AddDataContainer>
                    <Box>
                      {isPreShiftChecklistLoading ? (
                        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                      ) : (
                        <Box>
                          {shift?.preShiftCheckList?.length > 0 ? (
                            <>
                              {shift?.preShiftCheckList?.map((item: any) => {
                                return (
                                  <>
                                    <AssignJobText>{item?.categoryTitle}:</AssignJobText>
                                    {item?.options?.length > 0 ? (
                                      renderAddedItem(item?.id, item?.options, shift)
                                    ) : (
                                      <AssignJobText style={{ marginLeft: '0.25rem', marginRight: '1rem' }}>
                                        {t('assignJob:noItemsAddedYet')}
                                      </AssignJobText>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <AssignJobText>{t('assignJob:noDataYet')}</AssignJobText>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      {workOrderStatus === WorkOrderStatus.Accepted &&
                        ((isOtjJob && isDistrictManager) || (!isOtjJob && role === UserType.Vendor)) &&
                        !isAssociatePunchedIn && (
                          <Button
                            onClick={() => handleAddItemButton(shift)}
                            sx={{
                              backgroundColor: 'transparent',
                              padding: 0,
                              margin: 0,
                              minWidth: 0,
                            }}>
                            <img
                              src={Icons.AddTagIcon}
                              alt={addText}
                              style={{ width: '1.75rem', height: '1.75rem', marginRight: '2em' }}
                            />
                          </Button>
                        )}
                    </Box>
                  </AddDataContainer>
                </TimeAndInstructions>
              </ShiftDetailsContainer>
            </>
          );
        })
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1em',
          width: '100%',
          justifyContent: 'flex-end',
        }}>
        {startJobOpen && (
          <ModifyPopUp
            open={startJobOpen}
            handleClose={handleStartJobPopup}
            handleOnCloseJob={handleStartJob}
            loading={startJobLoading}
            heading={t('assignJob:startJob')}
            text={t('assignJob:confirmStartJob')}
          />
        )}

        {renderStartJobButton()}
      </div>
      <JobAssignmentCard
        open={isCardOpen}
        onClose={handleOnClose}
        assignmentType={assignmentType}
        jobData={jobData}
        shiftDetailId={shiftDetailId}
        shiftTimings={shiftTimings}
        isLoading={isLoading}
        workOrderData={workOrderData}
        workOrderId={workOrderId}
        subWorkOrderId={subWorkOrderId}
        isOnlySubVendor={isOnlySubVendor}
        isOnlyPrimeVendor={isOnlyPrimeVendor}
        hasChild={hasChild}
        teamData={teamData}
        setTeamData={setTeamData}
        originalTeamData={originalTeamData}
        setOriginalTeamData={setOriginalTeamData}
        associateTeamData={associateTeamData}
        setAssociateTeamData={setAssociateTeamData}
        originalAssociateTeamData={originalAssociateTeamData}
        setOriginalAssociateTeamData={setOriginalAssociateTeamData}
        checkedSupervisors={checkedSupervisors}
        setCheckedSupervisors={setCheckedSupervisors}
        checkedAssociates={checkedAssociates}
        setCheckedAssociates={setCheckedAssociates}
        isTeamDataLoading={isTeamDataLoading}
        isAssignSupervisorLoading={isAssignSupervisorLoading}
        isAssignAssociateLoading={isAssignAssociateLoading}
        fetchWorkOrderFacilityAccessInfo={fetchWorkOrderFacilityAccessInfo}
        facilityAccessData={facilityAccessData}
        isFacilityAccessLoading={isFacilityAccessLoading}
        proofOfServiceAndTaskData={tasksWithProof}
        setProofOfServiceAndTaskData={setTasksWithProof}
        isTaskRequireProofLoading={isTaskRequireProofLoading}
        isPreShiftChecklistLoading={isPreShiftChecklistLoading}
        checkListData={checkListData}
        setCheckListData={setCheckListData}
        // renderAssignedTeamData={renderAssignedTeamData}
        renderAssignedSupervisorTeamData={renderAssignedSupervisorTeamData}
        renderAssignedAssociateTeamData={renderAssignedAssociateTeamData}
        tasksWithProof={tasksWithProof}
        getWorkOrderDetails={getWorkOrderDetails}
        fetchDisableStartJobResult={fetchDisableStartJobResult}
        fetchScheduleDetails={fetchScheduleDetails}
      />
      <AddItem
        addItemOpen={addItemCardOpen}
        handleAddItemCardClose={handleAddItemClose}
        handleAddItem={handleAddItem}
        addItemData={addItemData}
        handleAddItemDelete={handleAddItemDelete}
        checkListData={checkListData}
        selectedCatalogue={selectedCatalogue}
        setSelectedCatalogue={setSelectedCatalogue}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <WarningMessage
        open={openShiftTimingsCheck}
        onClose={() => setOpenShiftTimingsCheck(false)}
        messageTitle={t('assignJob:shiftTimingsAreNotAddedYet')}
        messageContent={t('assignJob:pleaseAddShiftTimingsBeforeAssigningTheTeam')}
        onButtonClick={() => {
          handleDialogOpen(selectedShift);
          setOpenShiftTimingsCheck(false);
        }}
        showLoader={false}
        buttonText={t('assignJob:addShiftTiming')}
      />
      <WarningMessage
        open={openStartJobWarning}
        onClose={() => setOpenStartJobWarning(false)}
        messageTitle={t('assignJob:teamAssignment')}
        messageContent={t('assignJob:startJobWarningMsg')}
        onButtonClick={() => setOpenStartJobWarning(false)}
        buttonText={t('assignJob:okay')}
      />
      <WarningMessage
        open={isPrimeVendorAssignedSupervisors}
        onClose={() => setIsPrimeVendorAssignedSupervisors(false)}
        messageTitle={t('assignJob:teamAssignment')}
        messageContent={t('assignJob:primeVendorNotProvideSupervisors')}
        onButtonClick={() => setIsPrimeVendorAssignedSupervisors(false)}
        buttonText={t('assignJob:okay')}
      />
    </>
  );
};

export default AwardJob;
