/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle, CloseDialogBoxIcon, UpdateShiftDateWrapper, buttonStyles } from './UpdateShiftDate.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Controller, useForm } from 'react-hook-form';
import theme from '../../../../Shared/Themes/theme';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { DatePickerContainer } from '../JobDetails/OneTimeJobDetails.Style';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { getTimeFormat, hours } from '../../../../Shared/Utilities/utils';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';

interface UpdateShiftDate {
  openUpdateShift: boolean;
  handleShiftDateClose: () => void;
  isDataLoading: boolean;
  handleOnConfirm: (duration: any) => void;
  shiftData?: any;
  setStartTimeSelectOpen: (isOpen: boolean) => void;
  setEndTimeSelectOpen: (isOpen: boolean) => void;
  isStartTimeSelectOpen: boolean;
  isEndTimeSelectOpen: boolean;
}

const UpdateShiftDateCard = ({
  openUpdateShift,
  handleShiftDateClose,
  isDataLoading,
  handleOnConfirm,
  shiftData,
  setStartTimeSelectOpen,
  setEndTimeSelectOpen,
  isStartTimeSelectOpen,
  isEndTimeSelectOpen,
}: UpdateShiftDate): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework', 'rescheduleShiftDate']);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [isOffsetChecked, setIsOffsetChecked] = useState(false);
  const [offsetDays, setOffsetDays] = useState<number>();

  const { confirmButton, buttonText, textField } = buttonStyles;

  const { control, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      shiftDate: new Date().toISOString(),
      startTime: shiftData?.startTime ?? '',
      endTime: shiftData?.endTime ?? '',
      offsetDays: 0,
    },
  });

  const watchFields = watch();

  const validateTime = (startTime: string, endTime: string) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    return !(endHours < startHours || (endHours === startHours && endMinutes <= startMinutes));
  };

  const handleDialogClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    handleShiftDateClose();
  };

  const handleOffsetCheckboxChange = () => {
    setIsOffsetChecked((prev) => !prev);
  };

  const handleOnPressConfirm = async (duration: any) => {
    const timeDetails = {
      startTime: {
        hours: duration?.startTime?.split(':')[0] ?? '',
        minutes: duration?.startTime?.split(':')[1] ?? '',
      },
      endTime: {
        hours: duration?.endTime?.split(':')[0] ?? '',
        minutes: duration?.endTime?.split(':')[1] ?? '',
      },
      shiftDate: duration?.shiftDate,
      offsetDays: isOffsetChecked ? offsetDays : duration?.offsetDays,
    };
    handleOnConfirm(timeDetails);
  };

  useEffect(() => {
    if (shiftData?.endDateOffset) {
      setOffsetDays(shiftData?.endDateOffset);
      setIsOffsetChecked(true);
    }
  }, [shiftData?.endDateOffset]);

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
  }, []);

  const disableUpdateButton = () => {
    const { startTime, endTime } = getValues();
    return !(startTime && endTime && validateTime(startTime, endTime));
  };

  useEffect(() => {
    disableUpdateButton();
  }, [watchFields]);

  return (
    <UpdateShiftDateWrapper open={openUpdateShift} onClose={handleDialogClose}>
      <CloseDialogBoxIcon onClick={handleShiftDateClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseDialogBoxIcon>
      <CardTitle>{t('rescheduleShiftDate:rescheduleShift')}</CardTitle>
      <DatePickerContainer>
        <DateSelection
          requiredText="Date is mandatory"
          control={control}
          fieldName={'shiftDate'}
          label={t('taskRejectionAndRework:selectDate')}
          isExcludePastDate={true}
          isExcludeFutureDate={false}
          textFieldStyles={{
            '& .MuiOutlinedInput-root': {
              '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: theme.palette.primary.dark,
                borderRadius: '1rem',
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.primary.dark,
              '&.Mui-focused': {
                color: theme.palette.primary.dark,
              },
            },
          }}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <Controller
          control={control}
          name={FieldNames.startTime}
          defaultValue={shiftData?.startTime}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box>
              <TextField
                select
                fullWidth
                sx={{ ...textField, marginRight: '0' }}
                label={t('calendar:startTime')}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)} style={{ padding: 0 }}>
                        <img
                          src={isStartTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                          alt={isStartTimeSelectOpen ? 'down' : 'up'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  open: isStartTimeSelectOpen,
                  IconComponent: () => null,
                  onOpen: () => setStartTimeSelectOpen(true),
                  onClose: () => setStartTimeSelectOpen(false),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                      },
                    },
                  },
                }}>
                {hoursList.map((item) => (
                  <Options key={'startTime' + item.value} value={item.value}>
                    {item.label}
                  </Options>
                ))}
              </TextField>
              <span>{error && error.message}</span>
            </Box>
          )}
        />
        <Controller
          control={control}
          name={FieldNames.endTime}
          defaultValue={shiftData?.endTime}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box>
              <TextField
                select
                sx={{ ...textField, marginRight: '0' }}
                label={t('calendar:endTime')}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setEndTimeSelectOpen(!isEndTimeSelectOpen)} style={{ padding: 0 }}>
                        <img
                          src={isEndTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                          alt={isEndTimeSelectOpen ? 'down' : 'up'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  open: isEndTimeSelectOpen,
                  IconComponent: () => null,
                  onOpen: () => setEndTimeSelectOpen(true),
                  onClose: () => setEndTimeSelectOpen(false),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                      },
                    },
                  },
                }}>
                {hoursList.map((item) => (
                  <Options key={'endTime' + item.value} value={item.value}>
                    {item.label}
                  </Options>
                ))}
              </TextField>
              <span>{error && error.message}</span>
            </Box>
          )}
        />
      </DatePickerContainer>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
          <IconButton onClick={handleOffsetCheckboxChange} sx={{ padding: 0 }}>
            <img
              src={isOffsetChecked ? Icons.CheckedIcon : Icons.Unchecked}
              alt={isOffsetChecked ? 'checked' : 'unchecked'}
            />
          </IconButton>
          <span style={{ marginLeft: '0.5rem' }}>{t('rescheduleShiftDate:addEndDateOffset')}</span>
        </Box>
        {isOffsetChecked && (
          <Controller
            control={control}
            name="offsetDays"
            render={({ field }) => (
              <TextField
                label={t('rescheduleShiftDate:shiftDaysMessage')}
                variant="outlined"
                fullWidth
                type="number"
                {...field}
                value={offsetDays}
                onChange={(e) => {
                  setOffsetDays(parseInt(e.target.value));
                }}
                sx={{ ...buttonStyles.textField, width: '100%', marginBottom: '1.5rem' }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            )}
          />
        )}
      </Box>
      <Button
        sx={{
          ...confirmButton,
          opacity: isDataLoading || disableUpdateButton() ? 0.5 : 1,
        }}
        disabled={isDataLoading || disableUpdateButton()}
        onClick={handleSubmit(handleOnPressConfirm)}>
        {isDataLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {t('rescheduleShiftDate:confirm')}
          </Typography>
        )}
      </Button>
    </UpdateShiftDateWrapper>
  );
};

export default UpdateShiftDateCard;
